import React from "react";

export default function Mission() {
  return (
    <section className="mission">
      <div className="auto__container">
        <div className="mission__inner">
          <div className="mission__inner-title">
            <h3>OUR MISSION</h3>
          </div>
          <div className="mission__inner-text">
            <p>
              Focusing on maintaining the security and improving the scalability of various blockchain networks, we adhere to strict standards to ensure reliability and efficiency. Our mission includes verifying transactions and ensuring network security, which is key to advancing blockchain technology.
              <br />
              <br />
              <br />
              We actively support the principles of decentralization, contributing to the growth and stability of the projects. This is important for creating a transparent and trustworthy digital space where each participant can be confident in the fairness and openness of processes.
              <br />
              <br />
              <br />
              Recognizing the environmental impact of blockchain technologies, we support solutions that reduce energy use and carbon emissions, endorsing consensus mechanisms like Proof of Stake (PoS) and other efficient methods. This strategy not only lessens the ecological footprint but also enhances network efficiency and scalability, fostering a sustainable digital future.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
